import { useContext, useEffect, createContext, useState } from 'react';

const TemplatesContext = createContext({
  templates: [{}],
  onAddQuantity: (index, userStatus) => {},
  onSubtractQuantity: (index, userStatus) => {},
  selectedTemplate: null,
  updateSelectedTemplate: (template) => {}
});

const useTemplatesContext = () => useContext(TemplatesContext);

/* eslint-disable react/prop-types */
function TemplatesContextProvider({ children }) {
  const [templates, setTemplates] = useState([
    {
      id: 1,
      price: 12,
      // eslint-disable-next-line react/jsx-filename-extension
      title: <span>Book Coverage&nbsp;</span>,
      priceId: process.env.REACT_APP_BOOK_ID,
      product: 'book',
      quantity: 1,
      subTotal: 12
    },
    {
      id: 2,
      price: 9,
      title: <span>Script Coverage</span>,
      priceId: process.env.REACT_APP_SCRIPT_ID,
      product: 'script',
      quantity: 1,
      subTotal: 9
    }
  ]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await fetch('/api/templates');
        const data = await response.json();
        setTemplates(data);
      } catch (error) {
        console.log('Error');
      }
    };
    fetchTemplates();
  }, []);

  const onAddQuantity = (index, userStatus) => {
    if (userStatus === 'trialing') {
      return;
    }

    const updatedTemplates = [...templates];
    updatedTemplates[index].quantity += 1;
    // eslint-disable-next-line max-len
    updatedTemplates[index].subTotal =
      updatedTemplates[index].quantity * updatedTemplates[index].price;
    setTemplates(updatedTemplates);
  };

  const onSubtractQuantity = (index, userStatus) => {
    if (userStatus === 'trialing') {
      return;
    }

    if (templates[index].quantity === 1) {
      return;
    }

    const updatedTemplates = [...templates];
    updatedTemplates[index].quantity -= 1;
    // eslint-disable-next-line max-len
    updatedTemplates[index].subTotal =
      updatedTemplates[index].quantity * updatedTemplates[index].price;
    setTemplates(updatedTemplates);
  };

  const updateSelectedTemplate = (template) => {
    setSelectedTemplate(template);
  };

  return (
    <TemplatesContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        templates,
        onAddQuantity,
        onSubtractQuantity,
        selectedTemplate,
        updateSelectedTemplate
      }}>
      {children}
    </TemplatesContext.Provider>
  );
}

export { useTemplatesContext, TemplatesContextProvider };
