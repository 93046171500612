import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Skeleton } from '@mui/material';
import { isArray, isEmpty, startCase } from 'lodash-es';

import { getSubmissionsDataApi } from '../api/submissions-database';
import Divider from '../components/Divider/Divider';
import Button from '../components/UI/Button';
import Card from '../components/UI/Card';
import Progress from '../components/atoms/Progress/Progress';
import { LeftArrowIcon } from '../components/icons/icons';
import ListGeneratorSelectors from '../list-generator/ListGeneratorSelectors';
import useListStore from '../store/list-generator';
import { apiInstance } from '../utils/apiInstance';
import IdeaGeneratorMembers from './IdeaGeneratorMembers';
import { projectToState } from '../list-generator/ListGeneratorHome';

const INITIAL_STATE = {
  suggestions: [],
  intent: '',
  traits: null
};

export const DataCard = ({ label, children, className, contentClassName, isLoading }) => (
  <div className={twMerge('w-fit', className)}>
    {label && <label className="font-semibold text-sm">{label}</label>}
    {isLoading ? (
      <Skeleton height={50} width={200} className="!bg-gray-100" />
    ) : (
      <div
        className={twMerge(
          'text-white bg-gray-900 px-4 py-2 rounded-lg border border-gray-700 h-full',
          contentClassName
        )}>
        {children}
      </div>
    )}
  </div>
);

export const getDescription = (dropdown) => {
  switch (dropdown?.type?._id) {
    case 'writer':
      return dropdown.project?.writer_description;
    case 'director':
      return dropdown.project?.director_description;
    case 'cast':
      return dropdown?.character?.data?.description_coverage;
    default:
      return null;
  }
};

const IdeaGeneratorHome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = useListStore((store) => store.dropdown.type?._id);
  const dropdown = useListStore((store) => store.dropdown);
  const [submissions, setSubmissions] = useState([]);
  const [progress, setProgress] = useState(0);
  const [ideaState, setIdeaState] = useListStore((store) => [store.ideaState, store.setIdeaState]);
  const [submissionsLoading, setSubmissionsLoading] = useState(false);
  const description = getDescription(dropdown);

  useEffect(() => {
    async function fetchSubmissions() {
      setSubmissionsLoading(true);
      const { data, status } = await getSubmissionsDataApi(location);
      if (status === 200) setSubmissions(data?.data);
      setSubmissionsLoading(false);
    }
    fetchSubmissions();
  }, [location]);

  async function generateIdeas() {
    setProgress(0);
    try {
      setProgress(1);
      setIdeaState(INITIAL_STATE);

      const { data: intentData } = await apiInstance().post('list/get-user-intent/', {
        description,
        type
      });
      setIdeaState({ intent: intentData.user_intent });

      setProgress(5);

      const { data: traitsData } = await apiInstance().post('list/get-traits/', {
        user_intent: intentData.user_intent
      });
      setIdeaState((prev) => ({ ...prev, traits: traitsData.traits }));

      const { data: suggestionsData } = await apiInstance().post('list/get-suggestions/', {
        user_intent: intentData.user_intent,
        traits: traitsData.traits,
        credit_type: type
      });
      setIdeaState((prev) => ({
        ...prev,
        suggestions: suggestionsData.suggestions?.map((suggestion) => ({
          ...suggestion,
          projects: {
            ...suggestion.projects,
            past_projects: suggestion.projects.past_projects.map(projectToState),
            upcoming_projects: suggestion.projects.upcoming_projects.map(projectToState)
          }
        }))
      }));

      setProgress(10);
    } catch {
      setProgress(0);
    } finally {
      setProgress(100);
      setTimeout(() => setProgress(0), 500);
    }
  }

  const renderTraits = () => {
    const traits = ideaState.traits
      ? Object.keys(ideaState.traits).map((trait) => ({
          trait: startCase(trait),
          description: ideaState.traits[trait]
        }))
      : [];

    if (isEmpty(traits)) return null;

    return (
      <div className="flex gap-x-4 mb-8 text-sm">
        <div className={twMerge('w-max')}>
          <label className="font-semibold text-sm">Trait</label>
          <div className="flex flex-col gap-y-2 mt-2">
            {traits.map((trait) => (
              <DataCard
                key={trait.trait}
                className={'min-w-max w-full'}
                contentClassName={
                  'bg-gradient-to-l from-[#E04C11] to-[#FE6629] border-2 w-full text-center'
                }>
                {trait.trait}
              </DataCard>
            ))}
          </div>
        </div>
        <div className={twMerge('w-full')}>
          <label className="font-semibold text-sm">Description</label>
          <div className="flex flex-col gap-y-2 mt-2">
            {traits.map((trait) => {
              if (isArray(trait.description)) {
                return (
                  <div key={trait.trait} className="flex flex-wrap gap-2">
                    {trait.description.map((desc, idx) => (
                      <DataCard key={idx} className={'!min-h-[40px]'}>
                        {desc}
                      </DataCard>
                    ))}
                  </div>
                );
              }
              return (
                <DataCard key={trait.trait} className={'!min-h-[40px]'}>
                  {String(trait.description)}
                </DataCard>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderSuggestions = () => {
    if (!ideaState.suggestions?.length) return null;
    return (
      <IdeaGeneratorMembers
        onRefreshIdeas={() => generateIdeas(true)}
        isIdeaLoading={progress !== 0 && progress !== 100}
        talents={ideaState.suggestions}
        onSkip={(index) => {
          setIdeaState((prev) => ({
            ...prev,
            suggestions: prev.suggestions.filter((_, idx) => idx !== index)
          }));
        }}
      />
    );
  };

  return (
    <div className="flex flex-col gap-6 font-inter">
      <Card className="min-h-fit flex gap-4">
        <Button
          onClick={() => navigate('/list-builder/list')}
          variant="secondary"
          className="rounded-lg w-fit">
          <div className="w-max flex items-center gap-2 h-[40px]">
            <LeftArrowIcon /> Go Back To List
          </div>
        </Button>
        <div className="flex items-center w-full">
          <Progress value={progress} label="Generation..." />
        </div>
      </Card>
      <ListGeneratorSelectors
        submissions={submissions}
        submissionsLoading={submissionsLoading}
        description={description}
      />
      <Card className="min-h-fit !p-0" resetZIndex>
        <header className="p-4 flex justify-between items-center">Idea Generator</header>
        <Divider className="my-0" />
        <section className="p-4">
          <DataCard
            isLoading={progress !== 0 && progress !== 100 && !ideaState.intent}
            label="User Intent"
            className="mb-4">
            {ideaState?.intent || 'NA'}
          </DataCard>
          {renderTraits()}
          <Button
            loading={progress !== 0 && progress !== 100}
            onClick={generateIdeas}
            className="h-[40px] rounded-lg">
            Generate Ideas
          </Button>
        </section>
      </Card>
      {renderSuggestions()}
    </div>
  );
};

export default IdeaGeneratorHome;
