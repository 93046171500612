import { CSS } from '@dnd-kit/utilities';
import { DeleteIcon } from '../../icons/icons';
import GripIcon from '../../icons/GripIcon';
import Select from 'react-select';
import { useSortable } from '@dnd-kit/sortable';
import getFileTypeIcon from '../../../utils/getFileTypeIcon';
import { COVERAGE_TYPE_MAPPINGS } from '../../../utils/constant';

function SortableItem({ file, onRemove, onFileTypeChange, groupAll }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: file.id });
  const customTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#444556'
    }
  });
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      maxWidth: 245,
      background: '#292A35',
      borderRadius: '6px',
      border: '1px solid #444556'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#D5D6E3',
      fontSize: '0.875rem' 
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#292A35',
      borderRadius: '5px',
      color: '#D5D6E3',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'rgb(221, 77, 20, 0.5)' : '#292A35',
      color: state.isFocused ? '#ffffff' : '#D5D6E3',
      fontSize: '0.875rem'
    }),
  };

  const style = {
    background: '#101828',
    border: '1px solid #333441',
    transform: CSS.Transform.toString(transform),
    transition,
    color: '#fff'
  }

  const formatFileSize = (bytes) => {
    const mb = bytes / (1024 * 1024)
    return `${mb.toFixed(1)} MB`
  }
  const gripClass = groupAll ? 'cursor-move block transition-all' : 'cursor-move hidden';

  return (
    <div
      ref={setNodeRef}
      style={style}
      className='flex items-center justify-between p-3 bg-slate-800 rounded-lg mb-1.5'>
      <div className=' items-center sm:flex sm:space-x-4'>
        <div {...attributes} {...listeners} className={gripClass}>
          <GripIcon />
        </div>

        <Select
          styles={customStyles}
          theme={customTheme}
          options={COVERAGE_TYPE_MAPPINGS}
          getOptionLabel={(e) => (
            <div className="flex items-center">
            {e.icon && <span className="mx-2"><e.icon /></span>}
            {e.label}
          </div>
          
          )}
          onChange={(e) => onFileTypeChange(file.id, e.value)}
          value={COVERAGE_TYPE_MAPPINGS.find((type) => type.value === file.coverageType)}
        />
        <div className='hidden sm:block'>{getFileTypeIcon(file.name)}</div>
        <div>
          <p className='text-base'>{file.name}</p>
          <p className='text-xs text-gray-300 '>{formatFileSize(file.size)}</p>
        </div>
      </div>
      <button
        onClick={() => onRemove(file.id)}
        className='p-1 hover:text-red-500 rounded-lg hover:bg-slate-700 sm:p-2'>
        <DeleteIcon />
      </button>
    </div>
  );
}

export default SortableItem;
