import React, { useEffect, useState } from 'react';
import axios from 'axios';
import getAuthToken from '../../utils/cookie';

const StripePricingTable = ({ REACT_APP_STRIPE_TABLE_ID }) => {
  const [customerSessionSecret, setCustomerSessionSecret] = useState('');

  const getCustomerSession = async () => {
    try {
      const token = getAuthToken();

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}customer_session/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const { client_secret = '' } = response.data || {};
      setCustomerSessionSecret(client_secret);
    } catch (error) {
      console.error('Error in getCustomerSession:', error.message);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;

    document.body.appendChild(script);
    getCustomerSession();
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (!!customerSessionSecret && React.createElement('stripe-pricing-table', {
    'pricing-table-id': REACT_APP_STRIPE_TABLE_ID,
    'publishable-key': process.env.REACT_APP_STRIPE_PUBLISH_KEY,
    'customer-session-client-secret': customerSessionSecret
  }) )
};

export default StripePricingTable;
