import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import toast from 'react-hot-toast';
import SubscriptionCheckout from '../components/billing/SubscriptionCheckout';
import OneTimeCheckout from '../components/billing/OneTimeCheckout';
import { useUserDetialsContext } from '../context/user-details';

const { publishableKey } = require('../config.json');

const stripePromise = loadStripe(publishableKey.toString());

const Payment = () => {
  const [clientSecret, setClientSecret] = useState(null);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { userDetails } = useUserDetialsContext();
  const { tokenId } = userDetails;
  const { product } = params;
  const bookId = process.env.REACT_APP_BOOK_PRICE_ID;
  const scriptId = process.env.REACT_APP_SCRIPT_PRICE_ID;
  const credits =
    new URLSearchParams(location.search).get('credit') ||
    new URLSearchParams(location.search).get('credits');
  const subscriptionUrl = location.pathname.split('/')[2] === 'subscription';

  const fetchSecret = async () => {
    const creditsNum = +credits;

    if (isNaN(creditsNum) || creditsNum < 1 || !creditsNum) {
      setMessage('Please provide valid credits.');
      return;
    }

    if (!(product === 'book' || product === 'script')) {
      setMessage('Please provide valid product to buy credits.');
      return;
    }

    setMessage(null);

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}extra_book_tokens/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenId}`
      },
      body: JSON.stringify({
        product: params.product,
        price_id: params.product === 'book' ? bookId : scriptId,
        tokens: credits
      })
    });

    if (response.status === 200) {
      const { clientSecret: fetchedClientSecret } = await response.json();
      if (clientSecret) {
        console.log('clientSecret received');
      }
      setClientSecret(fetchedClientSecret);
    } else {
      const res = await response.json();
      toast.error(res.message);
      console.log('error', res.message);
      setTimeout(() => {
        navigate('/coverage');
      }, 2000);
    }
  };

  useEffect(() => {
    document.title = 'Payment | Fullframe';
    const metaDescription = document.querySelector('meta[name="description"]');
    metaDescription.setAttribute('content', 'Payment Page');

    if (!stripePromise) return;

    if (subscriptionUrl) return;

    fetchSecret();
  }, []);

  const options = {
    mode: 'setup',
    currency: 'usd',
    loader: 'auto',
    paymentMethodCreation: 'manual',
    payment_method_types: ['card', 'link']
  };

  if (subscriptionUrl) {
    return (
      <Elements stripe={stripePromise} options={options}>
        <SubscriptionCheckout />
      </Elements>
    );
  }

  if (stripePromise && clientSecret) {
    return (
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <OneTimeCheckout product={product} credits={credits} />
      </Elements>
    );
  }
  if (message) {
    return (
      <div className="flex items-center justify-center h-screen w-full text-xl text-primary font-filsonPro-book">
        {message}
      </div>
    );
  }
  return <div className="flex items-center justify-center h-screen w-full">Loading...</div>;
};

export default Payment;
