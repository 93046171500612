import { Helmet } from 'react-helmet';
import { Outlet, useRoutes } from 'react-router-dom';

import {
  Balance,
  Payment
  // SubscriptionCheckout,
} from '../billing/Billing';
import { BookCoverage, CombineCoverage } from '../coverage/Coverage';
import MainLayout from '../layouts/MainLayout';
import Login from '../login/Login';
import Signup from '../signup/Signup';
// import PaymentMethod from "../billing/PaymentMethods";
import AboutUs from '../about-us/AboutUs';
import PageContainer from '../components/UI/PageContainer';
import Contact from '../contact/Contact';
import CustomTemplate from '../custom-template/CustomTemplate';
import DataRetention from '../data-retention/DataRetention';
import DraftComparison from '../draft-comparison/DraftComparison';
import Faq from '../faq/Faq';
import IdeaGeneratorHome from '../idea-generator/IdeaGeneratorHome';
import LandingPage from '../landing/LandingPage';
import SimpleLayout from '../layouts/SimpleLayout';
import UnprotectedLayout from '../layouts/UnprotectedLayout';
import ListGeneratorHome from '../list-generator/ListGeneratorHome';
import NotFoundPage from '../page-404/Page404';
import PaymentComplete from '../payment-complete/PaymentComplete';
import PrivacyPolicy from '../privacy-policy/PrivacyPolicy';
import QueryScript from '../query-script/QueryScript';
import Query from '../query/Query';
import Refer from '../refer/Refer';
import SubmissionsDatabase from '../submissions-database/SubmissionsDatabase';
import TermsOfUse from '../terms-of-use/TermsOfUse';
import HelmetComponent from './HelmetComponent';
import FeedbackPage from '../feedback';

export default function Router() {
  const routes = [
    {
      path: '/',
      element: (
        <>
          <HelmetComponent
            cannonical={'/'}
            title="Home | FullFrame"
            description="Streamline your coverage workflow with our dedicated app and say goodbye to hours of tedious summarizing. Securely upload your script, and within minutes, FullFrame drafts a comprehensive, automated coverage report, sans comments. FullFrame can process pilots, TV scripts, and movie scripts."
          />
          <LandingPage />
        </>
      )
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/signup',
      element: <Signup />
    },
    {
      path: '',
      element: <MainLayout />,
      children: [
        {
          path: 'coverage',
          element: (
            <>
              <HelmetComponent
                cannonical={'/coverage'}
                title="Coverage | FullFrame"
                description="Streamline your coverage workflow with our dedicated app and say goodbye to hours of tedious summarizing. Securely upload your scriptIn the demo, it looked like we can choose more similar looking file icons. Their styling looked like they may be from different applications. Of course this is more of a 'product' side issue but if you can find something good in the meantime please add it, and within minutes, FullFrame drafts a comprehensive, automated coverage report, sans comments. FullFrame can process pilots, TV scripts, and movie scripts."
              />
              <PageContainer>
                <CombineCoverage />
              </PageContainer>
            </>
          )
        },
        {
          path: 'query',
          element: (
            <>
              <HelmetComponent
                title="Query | FullFrame"
                description="Query the uploaded script and get the coverage report in PDF format."
              />
              <PageContainer>
                <Query />
              </PageContainer>
            </>
          )
        },
        {
          path: 'draft-comparison',
          element: (
            <>
              <HelmetComponent
                cannonical={'/draft-comparison'}
                title="Draft Comparison | FullFrame"
              />
              <PageContainer>
                <DraftComparison />
              </PageContainer>
            </>
          )
        },
        {
          path: 'material-log',
          element: (
            <>
              <HelmetComponent
                title="Material Log | FullFrame"
                description="All your submissions in one place. Keep track of your submissions, see their status, and get notified when they are read."
              />
              <PageContainer className="px-4 md:px-6 lg:pr-8 lg:!pl-[13rem]">
                <SubmissionsDatabase />
              </PageContainer>
            </>
          )
        },
        {
          path: 'list-builder',
          element: (
            <>
              <Outlet />
            </>
          ),
          children: [
            {
              path: 'list',
              element: (
                <PageContainer>
                  <ListGeneratorHome />
                </PageContainer>
              )
            },
            {
              path: 'idea',
              element: (
                <PageContainer>
                  <IdeaGeneratorHome />
                </PageContainer>
              )
            }
          ]
        },
        {
          path: 'query',
          element: (
            <>
              <HelmetComponent
                title="Query | FullFrame"
                description="Query the uploaded script and get the coverage report in PDF format."
              />
              <PageContainer>
                <Query />
              </PageContainer>
            </>
          )
        },
        {
          path: 'query-script',
          element: (
            <>
              <HelmetComponent
                title="Query Script | FullFrame"
                description="Query the uploaded script and get the coverage report in PDF format."
              />
              <PageContainer>
                <QueryScript />
              </PageContainer>
            </>
          )
        },
        {
          path: 'about-us',
          element: (
            <>
              <HelmetComponent
                cannonical={'/about-us'}
                title="About Us | FullFrame"
                description="Learn more about FullFrame and our mission to revolutionize Hollywood Development."
              />
              <PageContainer>
                <AboutUs />
              </PageContainer>
            </>
          )
        },
        {
          path: 'billing',
          children: [
            {
              path: 'balance',
              element: (
                <>
                  <HelmetComponent
                    cannonical={'/balance'}
                    title="Billing | FullFrame"
                    description="Learn more about FullFrame and our mission to revolutionize Hollywood Development."
                  />
                  <PageContainer>
                    <Balance />
                  </PageContainer>
                </>
              )
            }
          ]
        },
        {
          path: 'contact-us',
          element: (
            <>
              <HelmetComponent
                cannonical={'/contact-us'}
                title="Contact Us | FullFrame"
                description="Contact FullFrame for support or inquiries."
              />
              <PageContainer>
                <Contact />
              </PageContainer>
            </>
          )
        },
        {
          path: 'faq',
          element: (
            <>
              <HelmetComponent
                cannonical={'/faq'}
                title="FAQ | FullFrame"
                description="Frequently asked questions."
              />
              <PageContainer>
                <Faq />
              </PageContainer>
            </>
          )
        },
        {
          path: 'terms-of-use',
          element: (
            <>
              <HelmetComponent
                cannonical={'/terms-of-use'}
                title="Terms of use | FullFrame"
                description="Terms of use."
              />
              <PageContainer>
                <TermsOfUse />
              </PageContainer>
            </>
          )
        },
        {
          path: 'privacy-policy',
          element: (
            <>
              <HelmetComponent
                cannonical={'/privacy-policy'}
                title="Privacy policy | FullFrame"
                description="Privacy policy."
              />
              <PageContainer>
                <PrivacyPolicy />
              </PageContainer>
            </>
          )
        },
        {
          path: 'referral-program',
          element: (
            <>
              <HelmetComponent
                cannonical={'/refer'}
                title="Refer | FullFrame"
                description="Refer a friend."
              />
              <PageContainer>
                <Refer />
              </PageContainer>
            </>
          )
        },
        {
          path: 'custom-template',
          element: (
            <>
              <HelmetComponent
                cannonical={'/custom-template'}
                title="Custom template | FullFrame"
                description="Upload a custom template of your choice."
              />
              <PageContainer>
                <CustomTemplate />
              </PageContainer>
            </>
          )
        },
        {
          path: 'data-retention',
          element: (
            <>
              <HelmetComponent
                cannonical={'/data-retention'}
                title="Data retention | FullFrame"
                description="Data retention."
              />
              <PageContainer>
                <DataRetention />
              </PageContainer>
            </>
          )
        }
      ]
    },
    {
      path: '',
      element: <SimpleLayout />,
      children: [
        {
          path: 'billing',
          children: [
            {
              path: 'subscription',
              element: <Payment />
            },
            {
              path: 'checkout/:product',
              element: <Payment />
            }
          ]
        }
      ]
    },
    {
      path: '',
      element: <UnprotectedLayout />,
      children: [
        {
          path: 'contact_us',
          element: <Contact className="p-2 sm:p-5 md:p-8" />
        },
        {
          path: '/feedback',
          element: <FeedbackPage />
        },
      ]
    },
    {
      path: '*',
      element: (
        <>
          <Helmet>
            <title>Page Not Found | FullFrame</title>
            <meta name="description" content="Oops! The page you're looking for does not exist." />
          </Helmet>
          <NotFoundPage />
        </>
      )
    },
    {
      path: 'payment-complete',
      element: <PaymentComplete />
    }
  ];

  return useRoutes(routes);
}
