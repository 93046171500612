import { ReactComponent as PDFIcon } from '../static/icons/pdf.svg';
import { ReactComponent as WordIcon } from '../static/icons/word.svg';
import { ReactComponent as SubmissionChat } from '../static/icons/submission-chat.svg';


export default (fileName) => {
    const fileExtension = fileName?.split('.')?.pop()?.toLowerCase();
    if (fileExtension === 'pdf') {
        return <PDFIcon className="h-9 stroke-white" />;
    } else if (['docx', 'doc']?.includes(fileExtension)) {
        return <WordIcon className="h-10 w-10 text-gray-400" />;
    } else {
        return <SubmissionChat className="h-10 w-10 text-gray-400" />;
    }

}