
import React from "react";
 const GripIcon = ({ className = '', onClick }) => (
  <svg cy={90} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g opacity="0.82">
  <circle cx="6.25" cy="9.9783" r="1.25" fill="#FCFCFD"/>
  <circle cx="12" cy="9.9783" r="1.25" fill="#FCFCFD"/>
  <circle cx="17.75" cy="9.9783" r="1.25" fill="#FCFCFD"/>
  <circle cx="6.25" cy="15.4783" r="1.25" fill="#FCFCFD"/>
  <circle cx="12" cy="15.4783" r="1.25" fill="#FCFCFD"/>
  <circle cx="17.75" cy="15.4783" r="1.25" fill="#FCFCFD"/>
  </g>
  </svg>
  


);
export default GripIcon