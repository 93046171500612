
import React from "react";
 const  MultiCoverageIcon = ({ className = '', onClick }) => (
  <svg width="150" height="84" viewBox="0 0 150 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_19267_3020)">
      <path d="M42.6118 23.0419L36.8366 14.794L52.0733 18.7058L45.2232 23.5023C44.8158 23.7876 44.3119 23.8993 43.8221 23.8129C43.3324 23.7266 42.897 23.4492 42.6118 23.0419ZM47.3741 26.5742C46.152 27.4298 44.6401 27.765 43.171 27.506C41.7018 27.2469 40.3957 26.4148 39.54 25.1928L33.0873 15.9773L13.1204 29.9583C11.8984 30.8139 11.0663 32.12 10.8072 33.5892C10.5482 35.0584 10.8834 36.5703 11.7391 37.7924L35.3991 71.5824C36.2548 72.8044 37.5609 73.6365 39.0301 73.8956C40.4992 74.1546 42.0111 73.8194 43.2332 72.9637L70.8796 53.6055C72.1016 52.7499 72.9337 51.4438 73.1928 49.9746C73.4518 48.5054 73.1166 46.9935 72.2609 45.7714L55.0536 21.1969L47.3741 26.5742Z" fill="#1E1D28" stroke="#E4E7EC" stroke-width="1.5" />
      <g clip-path="url(#clip1_19267_3020)">
        <path d="M40.582 40.7418C42.0603 42.8531 41.5472 45.7629 39.436 47.2412M39.436 47.2412C37.3248 48.7195 34.4149 48.2064 32.9366 46.0952M39.436 47.2412L40.9655 49.4256M40.9655 49.4256L38.7811 50.9552M40.9655 49.4256L43.1499 47.8961M37.9064 45.0568C37.0016 45.6904 35.7545 45.4705 35.121 44.5657L32.8267 41.2891C32.1931 40.3843 32.413 39.1372 33.3178 38.5036C34.2226 37.8701 35.4697 38.0899 36.1033 38.9948L38.3976 42.2714C39.0311 43.1762 38.8112 44.4233 37.9064 45.0568Z" stroke="#FCFCFD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </g>
    </g>
    <g clip-path="url(#clip2_19267_3020)">
      <path d="M125.82 36.3619L131.595 28.1141L133.13 43.7698L126.28 38.9733C125.873 38.6881 125.595 38.2527 125.509 37.763C125.423 37.2733 125.534 36.7693 125.82 36.3619ZM124.129 42.0451C122.907 41.1894 122.075 39.8833 121.816 38.4142C121.557 36.945 121.892 35.4331 122.748 34.211L129.201 24.9956L109.234 11.0146C108.012 10.159 106.5 9.82377 105.031 10.0828C103.561 10.3419 102.255 11.174 101.4 12.396L77.7396 46.186C76.8839 47.4081 76.5487 48.92 76.8078 50.3892C77.0668 51.8583 77.8989 53.1644 79.1209 54.0201L106.767 73.3783C107.989 74.234 109.501 74.5692 110.97 74.3101C112.44 74.0511 113.746 73.219 114.601 71.997L131.809 47.4224L124.129 42.0451Z" fill="#1E1D28" stroke="#E4E7EC" stroke-width="1.5" />
      <g clip-path="url(#clip3_19267_3020)">
        <path d="M112.339 41.0032L107.368 48.1025M112.339 41.0032C111.998 40.1329 111.315 39.2662 110.361 38.5984C109.407 37.9305 108.359 37.5846 107.424 37.5618L102.453 44.6611C103.388 44.6839 104.436 45.0298 105.39 45.6977C106.344 46.3655 107.027 47.2322 107.368 48.1025M112.339 41.0032C113.274 41.0261 114.322 41.372 115.276 42.0398C116.229 42.7076 116.913 43.5744 117.254 44.4447L112.283 51.544C111.942 50.6737 111.258 49.807 110.305 49.1391C109.351 48.4713 108.303 48.1254 107.368 48.1025" stroke="#FCFCFD" stroke-linecap="round" stroke-linejoin="round" />
      </g>
    </g>
    <path d="M86.4678 27.4146V17.3459L96.7053 29.2896H88.3428C87.8455 29.2896 87.3686 29.0921 87.0169 28.7404C86.6653 28.3888 86.4678 27.9119 86.4678 27.4146ZM88.3428 33.0396C86.8509 33.0396 85.4202 32.447 84.3653 31.3921C83.3104 30.3372 82.7178 28.9065 82.7178 27.4146V16.1646H58.3428C56.8509 16.1646 55.4202 16.7572 54.3653 17.8121C53.3104 18.867 52.7178 20.2978 52.7178 21.7896V63.0396C52.7178 64.5315 53.3104 65.9622 54.3653 67.0171C55.4202 68.072 56.8509 68.6646 58.3428 68.6646H92.0928C93.5846 68.6646 95.0154 68.072 96.0703 67.0171C97.1251 65.9622 97.7178 64.5315 97.7178 63.0396V33.0396H88.3428Z" fill="#1E1D28" stroke="#E4E7EC" stroke-width="1.5" />
    <g clip-path="url(#clip4_19267_3020)">
      <path d="M77.6855 46.3959H72.0855V47.2959H77.6918V46.3959H77.6855ZM81.0543 43.5021H80.0605H71.348C70.498 43.5021 69.8105 44.1896 69.8105 45.0396V54.5459H67.8418V55.7896C67.8418 56.6396 68.5293 57.3271 69.3793 57.3271H78.523C78.523 57.3271 78.6168 57.3271 78.6918 57.3209C79.4605 57.2396 80.0605 56.5834 80.0605 55.7959V46.2834H82.5918V45.0334C82.5918 44.1896 81.9043 43.5021 81.0543 43.5021ZM69.3793 56.4209C69.0293 56.4209 68.748 56.1334 68.748 55.7896V55.4521H76.423V55.7896C76.423 56.0084 76.4543 56.2209 76.5043 56.4209H69.3793ZM79.1605 55.7896C79.1605 56.1396 78.873 56.4209 78.5293 56.4209H77.4668C77.3793 56.2271 77.3293 56.0146 77.3293 55.7896V54.5459H70.7168V45.0396C70.7168 44.6896 71.0043 44.4084 71.348 44.4084H79.1605V55.7896ZM81.6918 45.3771H80.0668V45.0396C80.0668 44.7146 80.3105 44.4459 80.6293 44.4084L80.6668 44.4021H81.0605C81.4105 44.4021 81.6918 44.6896 81.6918 45.0334V45.3771ZM77.6855 49.0896H72.0855V49.9896H77.6918V49.0896H77.6855ZM76.2605 51.7834H72.0855V52.6834H76.2605V51.7834Z" fill="#FCFCFD" />
    </g>
    <defs>
      <clipPath id="clip0_19267_3020">
        <rect width="60" height="60" fill="white" transform="translate(0.217773 34.4146) rotate(-35)" />
      </clipPath>
      <clipPath id="clip1_19267_3020">
        <rect width="16" height="16" fill="white" transform="translate(26 42) rotate(-35)" />
      </clipPath>
      <clipPath id="clip2_19267_3020">
        <rect width="60" height="60" fill="white" transform="translate(100.633 0.414612) rotate(35)" />
      </clipPath>
      <clipPath id="clip3_19267_3020">
        <rect width="16" height="16" fill="white" transform="translate(108.177 33) rotate(35)" />
      </clipPath>
      <clipPath id="clip4_19267_3020">
        <rect width="16" height="16" fill="white" transform="translate(67.2178 42.4146)" />
      </clipPath>
    </defs>
  </svg>

);
export default MultiCoverageIcon