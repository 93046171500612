import { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import MultiCoverageIcon from '../../icons/MultiCoverageIcon';
import { ALLOWED_FILE_EXTENSIONS } from '../../../utils/constant';
import { uniqueId } from 'lodash-es';

export function FileUploadZone({ setFiles, files, coverageType }) {
  const MAX_FILES = 10;
  const [errorMessage, setErrorMessage] = useState(false);
  const [isMaxFilesExceeded, setIsMaxFilesExceeded] = useState(false);
  const filterValidFiles = (acceptedFiles) => {
    const Files = acceptedFiles.reduce((filteredFiles, file) => {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop().toLowerCase();
      if (ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
        filteredFiles.push({
          id: uniqueId('file'),
          name: file.name,
          size: file.size,
          type: file.type,
          coverageType: coverageType,
          data: file
        });
      }

      return filteredFiles;
    }, []);
    return Files;
  };

  useEffect(() => {
    const isExceeded = files.length >= MAX_FILES;
    setIsMaxFilesExceeded(isExceeded);
    setErrorMessage(isExceeded);
  }, [files.length]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const validFiles = filterValidFiles(acceptedFiles);
      if (files.length + validFiles.length > MAX_FILES) {
        setErrorMessage(true);
      } else {
        setErrorMessage(false);
        setFiles((prev) => [...prev, ...validFiles]);
      }
    },
    [coverageType, files.length, setFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const dropzoneBaseStyles = `
    bg-gradient-to-b from-gradientDark-100 to-gradientDark-200
    border-2 border-dashed rounded-lg p-4 text-center cursor-pointer
    transition-colors
  `;

  const dropzoneActiveStyles = isDragActive
    ? 'border-orange-500 bg-slate-900/50'
    : 'border-slate-800';

  const browseButtonStyles = `
    py-2 px-6 font-filsonPro-semibold text-primary border border-primary 
    rounded-md cursor-pointer upload-section transition-all duration-200
    hover:scale-105 hover:text-secondary hover:border-secondary 
    ${isMaxFilesExceeded ? 'opacity-50 !cursor-not-allowed' : 'hover:text-orange-400'}
  `;

  return (
    <div>
      <div
        {...getRootProps()}
        className={`${dropzoneBaseStyles} ${dropzoneActiveStyles}`}>
        <input {...getInputProps()} />
        <div className="flex flex-col items-center space-y-4">
          <MultiCoverageIcon size={48} className="text-gray-400" />
          <div className="space-y-2">
            <h3 className="text-lg font-medium text-white">
              Upload Individual Or Multiple Files
            </h3>
            <div className="text-gray-400">
              <p>
                Drag & Drop Your Files Here
                <br />
                or
              </p>
            </div>
            <button
              className={browseButtonStyles}
              disabled={isMaxFilesExceeded}>
              Browse Files
            </button>
            {(errorMessage || isMaxFilesExceeded) && (
              <p className="mt-4 text-red-500">You can only upload up to 10 files.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
