import { useEffect, useMemo, useState } from 'react';
import { FileUploadZone } from '../../components/coverage/upload/FileUploadZone';
import { CoverageTypeSelector } from '../../components/coverage/combineCoverage/CoverageTypeSelector';
import { DraggableFileList } from '../../components/coverage/combineCoverage/DraggableFileList';
import Button from '../../components/UI/Button';
import DownloadFileIcon from '../../components/icons/DownloadFileIcon';
import axios from 'axios';
import getAuthToken from '../../utils/cookie';
import { CoverageConfirmationModal } from '../../components/coverage/Modal/CoverageConfirmationModal';
import { InsufficientTokensModal } from '../../components/coverage/Modal/InsufficientTokensModal';
import { useUserDetialsContext } from '../../context/user-details';
import ReportTypeEnum from '../../enums/CoverageType.enum';
import toast from 'react-hot-toast';
import { useSubmissionsContext } from '../../context/submissions';
import GroupAllConfirmationModal from '../../components/coverage/Modal/GroupAllConfirmationModal';
import isProcessingTooLong from '../../utils/isProcessingTooLong';
import parseDate from '../../utils/parseDate';
import { Link } from 'react-router-dom';
import { ReactComponent as ReadingLogIcon } from '../../static/icons/readinglog.svg';

export default function CombineCoverage() {
  const { userDetails, updateUserTokens } = useUserDetialsContext();
  const { setSubmissionData, submissionsData } = useSubmissionsContext();
  const { book_tokens, extra_book_tokens, script_tokens, extra_script_tokens } = userDetails;
  const savedCoverageType = localStorage.getItem('coverageType');
  const [coverageType, setCoverageType] = useState(savedCoverageType || '');
  const [files, setFiles] = useState([]);
  const [groupAll, setGroupAll] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInsufficientModal, setShowInsufficientModal] = useState(false);
  const [showGroupAllModal, setShowGroupAllModal] = useState(false);

  const availableCredits = {
    [ReportTypeEnum.BOOK]: extra_book_tokens + book_tokens,
    [ReportTypeEnum.SCRIPT]: extra_script_tokens + script_tokens
  };

  const handleCoverageTypeChange = (newCoverageType) => {
    setCoverageType(newCoverageType);
    localStorage.setItem('coverageType', newCoverageType);
  };

  const handleGroupAllConfirm = () => {
    setShowGroupAllModal(false);
    setGroupAll(true);
  };

  const handleGroupAllClose = () => {
    setShowGroupAllModal(false);
    setGroupAll(false);
  };

  const processingCoverages = useMemo(() => {
    return submissionsData.reduce((totals, submission) => {
      const createdAt = parseDate(submission?.created_at);
      if (submission.status === 'PROCESSING' && !isProcessingTooLong(createdAt)) {
        totals += 1;
      }
      return totals;
    }, 0);
  }, [submissionsData]);

  const calculateTotalTokens = useMemo(() => {
    return files.reduce(
      (totals, file) => {
        const coverage = groupAll ? coverageType : file.coverageType;
        const tokenCount = 1;

        if (coverage === ReportTypeEnum.BOOK) {
          totals.BOOK += tokenCount;
        } else {
          totals.SCRIPT += tokenCount;
        }

        return totals;
      },
      { BOOK: 0, SCRIPT: 0 }
    );
  }, [files, coverageType, groupAll]);

  const handleGenerateClick = () => {
    if (
      availableCredits[ReportTypeEnum.BOOK] >= calculateTotalTokens.BOOK &&
      availableCredits[ReportTypeEnum.SCRIPT] >= calculateTotalTokens.SCRIPT
    ) {
      setShowConfirmModal(true);
    } else {
      setShowInsufficientModal(true);
    }
  };

  const handleConfirmGenerate = () => {
    setShowConfirmModal(false);
    updateUserTokens(-calculateTotalTokens.BOOK, -calculateTotalTokens.SCRIPT);
    handleCoverage();
    setFiles([])
    toast.success('Your coverage report is running. You can view it in the material log.');
  };

  useEffect(() => {
    if (groupAll && files) {
      const fistFile = files.at(0);
      const fileFileCoverageType = fistFile.coverageType;
      setCoverageType(fistFile.coverageType);
      setFiles((prevFiles) => {
        return prevFiles.map((file) => ({ ...file, coverageType: fileFileCoverageType }));
      });
    }
  }, [groupAll]);

  useEffect(() => {
    if (groupAll) {
      setFiles((prevFiles) => {
        return prevFiles.map((file) => ({ ...file, coverageType }));
      });
    }
  }, [coverageType]);

  const handleCoverage = () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file.data);
      formData.append('filesCoverageType', file.coverageType);
    });

    formData.append('groupAll', groupAll);
    formData.append('coverageType', coverageType);

    axios.post(`${process.env.REACT_APP_BACKEND_URL}combine_coverage/`, formData, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`
      }
    });

    if (groupAll) {
      const filNamesArray = files.map((file) => file.name);
      const newSubmission = {
        isArchived: false,
        priority: 'Available Material',
        status: 'PROCESSING',
        type: 'SCRIPT',
        title: filNamesArray.join(', '),
        company_name: '',
        note: '',
        created_at: new Date()
      };
      setSubmissionData((prev) => [newSubmission, ...prev]);
    } else {
      const newSubmissions = files.map((file) => {
        return {
          isArchived: false,
          priority: 'Available Material',
          status: 'PROCESSING',
          type: file.coverageType,
          title: file.name,
          company_name: '',
          note: '',
          created_at: new Date()
        };
      });
      setSubmissionData((prev) => [...newSubmissions, ...prev]);
    }
  };

  const reportsInprogressText = !!processingCoverages && (
    <div className="grid justify-items-center gap-1 ">
      {`${processingCoverages} Report${processingCoverages === 1 ? '' : 's'} in Progress`}
      <div className="flex gap-1 font-filsonPro-semibold text-primary transition-all duration-200 hover:scale-105 hover:text-secondary">
        <Link className="flex gap-1" to="/material-log">
          <ReadingLogIcon />
          View in Material Log
        </Link>
      </div>
    </div>
  );
  

  return (
    <>
      <div className="font-inter bg-backgroundDark border-gray-800">
        <main className="flex-1 p-4 rounded-2xl border border-gray-800 bg-gradient-to-b from-gradientLight-100 to-gradientLight-200">
          <h1 className="text-lg md:text-xl font-filsonPro-bold">Generate Coverage</h1>
          <p className="mb-2 font-filsonPro-light text-gray-400 font-semibold">
            Select the type of material then upload a .pdf or .docx file to generate coverage.
          </p>

          <CoverageTypeSelector selected={coverageType} onSelect={handleCoverageTypeChange} />
          {Boolean(coverageType) && (
            <div className="bg-[#101828] border-slate-700 border p-4 rounded-2xl">
              <FileUploadZone files={files} coverageType={coverageType} setFiles={setFiles} />

              {files.length > 0 && (
                <DraggableFileList
                  setShowGroupAllModal={setShowGroupAllModal}
                  setCoverageType={handleCoverageTypeChange}
                  setGroupAll={setGroupAll}
                  groupAll={groupAll}
                  files={files}
                  setFiles={setFiles}
                />
              )}
            </div>
          )}
          {files.length > 0 && (
            <div className="mt-8 flex justify-center items-center space-x-2">
              <Button
                onClick={handleGenerateClick}
                className="!p-6 !px-4 text-md font-medium rounded-lg ">
                <DownloadFileIcon />
                Generate Coverage
              </Button>
              <div>{reportsInprogressText}</div>
            </div>
          )}
        </main>
      </div>
      {!files.length && (
        <div className="font-inter !text-white flex justify-center items-center">
          {reportsInprogressText}
        </div>
      )}

      <GroupAllConfirmationModal
        open={showGroupAllModal}
        onClose={handleGroupAllClose}
        onConfirm={handleGroupAllConfirm}
      />

      <CoverageConfirmationModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmGenerate}
        groupAll={groupAll}
        requiredCredits={calculateTotalTokens}
      />

      <InsufficientTokensModal
        open={showInsufficientModal}
        onClose={() => setShowInsufficientModal(false)}
        availableCredits={availableCredits}
        requiredCredits={calculateTotalTokens}
      />
    </>
  );
}
