import ReportTypeEnum from '../../../enums/CoverageType.enum';

export default function RequiredCreditsList({ requiredCredits }) {
  const coverageTypes = Object.values(ReportTypeEnum);
  return (
    <div className="border-t">
      {coverageTypes.map((type) => {
        const requiredAmount = requiredCredits[type] || 0;
        if (requiredAmount > 0) {
          return (
            <div key={type}>
              <div className="border-b flex justify-between capitalize p-3 font-bold text-orange-600">
                <span>{type.toLowerCase()}</span>
                <span>
                  {requiredAmount} {requiredAmount > 1 ? 'credits' : 'credit'}
                </span>
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}
