import { useNavigate } from 'react-router-dom';

import Button from '../components/UI/Button';
import NotFoundIcon from '../static/images/404-error.png';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="text-gray-400 flex flex-col gap-2 h-screen justify-center items-center bg-gradient-to-b from-gradientLight-100 to-gradientLight-200">
      <div className="text-center p-4 sm:p-8 text-lg mx-4 rounded-xl bg-white ">
      <img
          src={NotFoundIcon}
          alt="Not found icon"
          className="w-16 sm:w-20 h-16 sm:h-20 mx-auto mb-2 sm:mb-4"
        />
        <h1 className="text-center text-lg font-bold">Not Found.</h1>
        <p className="text-base font-semibold mt-1">
          Oops! The page you&apos;re looking for does not exist.
        </p>
        <Button
          onClick={() => navigate('/coverage', { replace: true })}
          className="text-base md:text-[1.08rem] mt-4 font-filsonPro-book"
        >
          Go back to Coverage
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
