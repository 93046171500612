import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const styles = {
  button: 'px-4 py-2 rounded-lg transition-colors',
  cancelButton: 'border border-gray-300 hover:bg-gray-50',
  confirmButton: 'bg-orange-500 text-white hover:bg-orange-600'
};

export default function GroupAllConfirmationModal({ open, onClose, onConfirm }) {
  return (
    <Dialog maxWidth='sm' fullWidth open={open} onClose={onClose}>
      <DialogTitle className="bg-orange-600 text-white">Confirm Grouping</DialogTitle>
      <DialogContent className=" !py-4">
        <div className="space-y-4">
          <p className="text-base text-gray-700">
            Selecting this option changes all uploads to a single type—either feature, series, or book—and generates a Combined Coverage Report.
          </p>
          <p className="text-base text-gray-700">
            The report includes a summary for each submission along with an overall summary covering all submissions. This is optimal for covering entire television, feature, or book series.
          </p>
          <p className="text-sm text-gray-600 italic">
            *Each upload will still use a credit. For example, 8 episodes submitted for combined coverage will use 8 script credits.
          </p>
          <p className="text-sm text-gray-600">
            Click <span className="font-bold">Confirm</span> to proceed with grouping your uploads.
          </p>
        </div>
      </DialogContent>
      <DialogActions className="p-4 flex justify-end gap-4 mb-1.5 mr-3.5">
        <button className={`${styles.button} ${styles.cancelButton}`} onClick={onClose}>
          Cancel
        </button>
        <button className={`${styles.button} ${styles.confirmButton}`} onClick={onConfirm}>
          Confirm
        </button>
      </DialogActions>
    </Dialog>
  );
}
