import { COVERAGE_TYPE_MAPPINGS } from '../../../utils/constant';

export function CoverageTypeSelector({ selected, onSelect }) {

  const getButtonClasses = (isSelected) => {
    const baseClasses =
      'flex items-center justify-center px-1 space-x-2 py-1.5 rounded-lg border-2 transition-all duration-300 ease-in-out';
    const selectedClasses = 'border-[#E04C11] bg-[#2C111F] text-white ';
    const unselectedClasses =
      'border-slate-700 hover:border-[#E04C11] hover:bg-[#2C111F] hover:text-white text-gray-400 bg-[#101828]';

    return `${baseClasses} ${isSelected ? selectedClasses : unselectedClasses}`;
  };

  return (
    <div className="grid sm:grid-cols-3 gap-4 my-4">
      {COVERAGE_TYPE_MAPPINGS.map((type) => {
        const { icon: Icon } = type;
        const isSelected = selected === type.value;

        return (
          <button
            key={type.value}
            onClick={() => onSelect(type.value)}
            className={getButtonClasses(isSelected)}>
            <Icon />
            <span className="text-md">{type.label}</span>
          </button>
        );
      })}
    </div>
  );
}
