import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUserDetialsContext } from '../../context/user-details';
import Logo from '../../static/images/logo.png';
import UploadFileModal from '../coverage/Modal/UploadFileModal';
import Backdrop from '../UI/Backdrop';
import Button from '../UI/Button';
import MainHeader from './MainHeader';
import NavLinks from './NavLinks';
import NavlinksSkeleton from './NavlinksSkeleton';
import SideDrawer from './SideDrawer';
import UserDetails from './UserDetails';

const MainNavigation = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(window.innerWidth > 1024);
  const [showModal, setShowModal] = useState(false);
  const { userDetails } = useUserDetialsContext();
  const {
    status,
    book_tokens: bookCredits,
    script_tokens: scriptCredits,
    extra_script_tokens,
    extra_book_tokens
  } = userDetails;
  const location = useLocation();
  const { pathname } = location;

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const returnNavbarHeader = () => {
    if (status) {
      if (status !== 'not_subscribed') {
        return (
          <div className="mx-auto text-white text-[0.625rem]">
            <span className="font-semibold tracking-wide">Credits:</span>{' '}
            <span className="inline-block ml-1 px-1.5 lg:px-[0.5625rem] py-0.5 text-primary bg-[linear-gradient(#E04C1133,#FE662933)] rounded-[9px] font-semibold">
              {(scriptCredits + extra_script_tokens) || 0} Script
            </span>
            <span className="inline-block ml-1 px-1.5 lg:px-[0.5625rem] py-0.5 text-primary bg-[linear-gradient(#E04C1133,#FE662933)] rounded-[9px] font-semibold">
              {(bookCredits + extra_book_tokens) || 0} Book
            </span>
          </div>
        );
      } else {
        if (pathname !== '/billing/balance')
          return (
            <Button
              element="button"
              onClick={() => setShowModal(true)}
              className="font-filsonPro-semibold tex-base ltext-lg tracking-wide !h-fit !py-1.5 !font-normal mx-auto !w-[90%]">
              Start Free Trial
            </Button>
          );
      }
    } else {
      <div className="flex items-center gap-1 px-5 animate-pulse">
        <div className="py-2.5 w-16 bg-gray-300 rounded-3xl" />
        <div className="py-2.5 w-16 rounded-3xl bg-gray-300 " />
        <div className="py-2.5 w-16 rounded-3xl bg-gray-300 " />
        <span className="sr-only">Loading...</span>
      </div>;
    }
  };

  const returnNavLinks = () => {
    if (status) {
      return <NavLinks />;
    } else {
      return <NavlinksSkeleton />;
    }
  };

  useEffect(() => {
    const minWidth = 1024;
    // With this useEffect, we can set the drawerIsOpen state to
    // true if the window width is greater than 1024px
    if (window.innerWidth > minWidth) {
      setDrawerIsOpen(true);
    }

    const handleResize = () => {
      if (window.innerWidth > minWidth) {
        setDrawerIsOpen(true);
      } else {
        setDrawerIsOpen(false);
      }
    };

    const handleWidthResize = () => {
      // Compare the current window width with the previous width
      if (window.innerWidth !== windowWidth) {
        handleResize();
      }
    };

    // Initialize the previous width
    const windowWidth = window.innerWidth;

    // Attach the event listener on component mount
    window.addEventListener('resize', handleWidthResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleWidthResize);
    };
  }, []);

  return (
    <>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} showModal className="lg:!hidden" />}
      <SideDrawer show={drawerIsOpen}>
        <div className="flex flex-col h-full">
          <div className="relative flex flex-1 z-20 overflow-hidden">
            <div className="flex flex-col gap-4 w-full overflow-auto">
              <Link to="/coverage" className="block mt-5 mx-auto cursor-pointer">
                <img src={Logo} alt="Fullframe" className="h-[30px] lg:h-[40px]" />
              </Link>
              {returnNavbarHeader()}
              <nav className="main-navigation__drawer-nav h-] px-3 overflow-auto">
                {returnNavLinks()}
              </nav>
            </div>
            <i
              className="fas fa-times absolute px-0.5 top-2.5 right-3 text-lg text-white hover:text-primary lg:hidden cursor-pointer"
              onClick={() => setDrawerIsOpen(false)}
            />
          </div>
          <div className="mb-4">
            <UserDetails />
            <div className="mt-2 sm:mt-3 mx-auto text-xs w-full text-center text-[#667085]">
              Copyright © Fullframe 2024
            </div>
          </div>
        </div>
      </SideDrawer>
      <MainHeader>
        <button
          type="button"
          className="main-navigation__menu-btn relative px-0.5 bg-transparent flex flex-col justify-around cursor-pointer mr-3 subscription-section"
          onClick={openDrawerHandler}>
          <i className="fas fa-bars text-white hover:text-primary text-base" />
        </button>
        <Link to="/" className="text-white">
          <img src={Logo} alt="Logo" className="h-[30px]" />
        </Link>
      </MainHeader>
      <UploadFileModal
        showMessage={showModal}
        setShowMessage={setShowModal}
        status="not_subscribed"
      />
    </>
  );
};

export default MainNavigation;
