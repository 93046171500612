import ReactDOM from 'react-dom';

function SideDrawer({ show, onClick, children, className = '' }) {
  const content = (
    <aside
      className={`
          ${show ? '-translate-x-0 opacity-100' : '-translate-x-full opacity-80'} 
          side-drawer fixed w-[189px] 
          transition-all duration-500 h-[130vh] 
          bg-backgroundDark card-shadow 
          overflow-y-scroll overflow-x-hidden z-[1020] ${className}
        `}
      onClick={onClick}>
      <div className="h-[109vh] sm:h-screen">{children}</div>
    </aside>
  );
  return ReactDOM.createPortal(content, document.getElementById('drawer-hook'));
}

export default SideDrawer;
