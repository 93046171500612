import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import RequiredCreditsList from './RequiredCredits';

const styles = {
  button: 'px-4 py-2 rounded-lg transition-colors',
  cancelButton: 'border border-gray-300 hover:bg-gray-50',
  confirmButton: 'bg-orange-500 text-white hover:bg-orange-600'
};

export function CoverageConfirmationModal({ open, onClose, onConfirm, groupAll, requiredCredits }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className="bg-orange-600 text-white">Confirm Credit Usage</DialogTitle>
      <DialogContent className="sm:max-w-[450px] !py-4">
        <div className="space-y-4">
          <p className="text-base text-gray-700">
            You're about to use credits for the following coverage types:
          </p>
          <RequiredCreditsList requiredCredits={requiredCredits} />
          {groupAll && (
            <p className="text-sm text-gray-600 italic">
              *Please note that you have selected combined coverage and will receive a single,
              combined coverage report for all of your uploads.
            </p>
          )}
          <p className="text-sm text-gray-600">
            Click <span className="font-bold">Confirm</span> to proceed with generating your report.
          </p>
        </div>
      </DialogContent>
      <DialogActions className="p-4 flex justify-end gap-4 mb-1.5 mr-3.5 ">
        <button className={`${styles.button} ${styles.cancelButton}`} onClick={onClose}>
          Cancel
        </button>
        <button className={`${styles.button} ${styles.confirmButton}`} onClick={onConfirm}>
          Confirm
        </button>
      </DialogActions>
    </Dialog>
  );
}
