import React from 'react';
import ReportTypeEnum from '../../../enums/CoverageType.enum';

const styles = {
  headerCell: 'py-3 px-4 text-left font-medium text-gray-600 text-center',
  dataCell: 'py-3 px-4 text-center font-semibold',
  statusBadge: 'inline-flex px-2 py-1 rounded-full text-xs font-medium',
};

const creditInfo = {
  [ReportTypeEnum.BOOK]: 'Book Credits',
  [ReportTypeEnum.SCRIPT]: 'Script Credits',
  [ReportTypeEnum.SERIES]: 'Series Credits',
  [ReportTypeEnum.PODCAST]: 'Podcast Credits'
};

function CreditsTable({ creditsData }) {
  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr className="border-b">
            <th className={styles.headerCell}>Type</th>
            <th className={styles.headerCell}>Required</th>
            <th className={styles.headerCell}>Available</th>
            <th className={styles.headerCell}>Status</th>
          </tr>
        </thead>
        <tbody>
          {creditsData.map(
            ({ type, required, available, insufficient }) =>
              !!required && (
                <tr key={type} className={`border-b`}>
                  <td className="py-3 px-4 font-medium">{creditInfo[type]}</td>
                  <td className={styles.dataCell}>{required}</td>
                  <td className={styles.dataCell}>{available}</td>
                  <td className={styles.dataCell}>
                    <span
                      className={`${styles.statusBadge} ${
                        insufficient ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'
                      }`}>
                      {insufficient ? 'Insufficient' : 'Available'}
                    </span>
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default CreditsTable;
