import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CreditsTable from './CreditsTable';
import ReportTypeEnum from '../../../enums/CoverageType.enum';

export function InsufficientTokensModal({ open, onClose, availableCredits, requiredCredits }) {
  const navigate = useNavigate();
  const coverageTypes = Object.values(ReportTypeEnum);

  const creditsData = coverageTypes.map((type) => ({
    type,
    required: requiredCredits[type] || 0,
    available: availableCredits[type] || 0,
    insufficient: (requiredCredits[type] || 0) > (availableCredits[type] || 0)
  }));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      className="w-full max-w-lg mx-auto">
      <DialogTitle className="bg-orange-500 text-white">Insufficient Credits</DialogTitle>
      <DialogContent>
        <CreditsTable creditsData={creditsData} />
      </DialogContent>
      <DialogActions className="flex justify-between mt-4 bg-gray-50 rounded-b-lg p-4">
        <button className="px-4 py-2 rounded-lg border border-gray-300 hover:bg-gray-50" onClick={onClose}>
          Close
        </button>
        <button
          className="px-4 py-2 rounded-lg transition-colors bg-orange-500 text-white hover:bg-orange-600"
          onClick={() => navigate('/billing/balance')}>
          Purchase Credits
        </button>
      </DialogActions>
    </Dialog>
  );
}
