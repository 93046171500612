
import React from "react";
const DownloadFileIcon = ({ className = '', onClick }) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.125 11.9783V17.9783L11.0417 15.9783" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.12467 17.9783L7.20801 15.9783" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21.5837 10.9783V15.9783C21.5837 20.9783 19.667 22.9783 14.8753 22.9783H9.12533C4.33366 22.9783 2.41699 20.9783 2.41699 15.9783V9.97827C2.41699 4.97827 4.33366 2.97827 9.12533 2.97827H13.917" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21.5837 10.9783H17.7503C14.8753 10.9783 13.917 9.97827 13.917 6.97827V2.97827L21.5837 10.9783Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
export default DownloadFileIcon