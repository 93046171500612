import { useState } from 'react'
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import SortableItem from './SortableItem'

export function DraggableFileList({ files, setFiles, groupAll, setGroupAll, setCoverageType, setShowGroupAllModal }) {
  const [isDragging, setIsDragging] = useState(false)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  const handleRemoveFile = (id) => {
    setFiles(files.filter((file) => file.id !== id))
  }

  const onFileTypeChange = (id, newType) => {
    groupAll && setCoverageType(newType);
    setFiles((prevFiles) => {
      return prevFiles.map((file) => {
        if (file.id === id || groupAll) {
          return { ...file, coverageType: newType };
        }
        return file;
      });
    });
  };
  
  const handelCombineCoverageSwitch = () => {
    groupAll ? setGroupAll((prev) => !prev) : setShowGroupAllModal(true);
  };

  const handleDragEnd = (event) => {
    setIsDragging(false)
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = files.findIndex((file) => file.id === active.id)
      const newIndex = files.findIndex((file) => file.id === over.id)
      const newFiles = arrayMove(files, oldIndex, newIndex)
      setFiles(newFiles)
    }
  }
  const isMaxFileUpload = files.length === 10
  const SortableItemContainerClassName = `p-2 rounded-xl transition-all ${(isDragging || groupAll) && ' bg-[#372628] '}}`;
  const ToolTipText =  "Use 'Group All' to consolidate and generate a report for all files of the same coverage type.";

  return (
    <div className="mt-8 p-3 rounded-2xl bg-gradient-to-b from-gradientDark-100 to-gradientDark-200">
      <div className="flex items-center justify-between border-b mb-2 pb-2 border-gray-800">
        <h3 className="pl-3 text-lg font-medium text-white">
          {files.length}
          {isMaxFileUpload && '/10'} Files Uploaded
        </h3>
        {/* <Tooltip title={ToolTipText} enterTouchDelay={0} leaveTouchDelay={3000} arrow>
          <span className="sm:flex items-center">
            <Switch
              checked={groupAll}
              onChange={handelCombineCoverageSwitch}
              color="warning"
            />
            <p>Combined Coverage</p>
          </span>
        </Tooltip> */}
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={handleDragEnd}>
        <SortableContext items={files} strategy={verticalListSortingStrategy} disabled={!groupAll}>
          <div className={SortableItemContainerClassName}>
            {files.map((file) => (
              <SortableItem
                groupAll={groupAll}
                key={file.id}
                file={file}
                onRemove={handleRemoveFile}
                onFileTypeChange={onFileTypeChange}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
}
